var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.manageServices'))+" ")]),_c('v-spacer'),(_vm.selected.length > 0)?_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":_vm.deleteServices}},[_c('v-icon',[_vm._v("delete")]),_c('v-badge',[_vm._v(_vm._s(_vm.selected.length))])],1):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","to":"/main/admin/services/create"}},[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.createService')))])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.services,"show-select":""},scopedSlots:_vm._u([{key:"item.images_allowed",fn:function(ref){
var item = ref.item;
return [_c('tr',[(item.images_allowed)?_c('v-icon',[_vm._v("checkmark")]):_vm._e()],1)]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"justify-center layout px-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","to":{ name: 'main-admin-services-edit', params: { id: item.id } }}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""},on:{"click":function($event){return _vm.deleteService(item.id)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.delete')))])])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }